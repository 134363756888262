<template>
  <div class="products category-med">
    <v-container class="pb-0">
      <Breadcrumb :items="breadcrumb" />
      <ProposalSlider
        v-if="certificatesProposals.length > 0"
        :title="certificatesTitle"
        :proposals="certificatesProposals"
      />
      <component
        v-if="headerProposals.length > 0"
        :is="headerMode"
        :title="headerTitle"
        :proposals="headerProposals"
        paginationClass="pagination-header"
        :cols="1"
        :sm="2"
        :md="2"
        :lg="2"
      />

      <CategoryTitle :category="category" :count="count" />
    </v-container>
    <v-container v-if="category.children">
      <div class="subcategories">
        <v-chip
          v-for="subCategory in category.children"
          :key="subCategory.categoryId"
          label
          link
          :to="{ name: 'Category', params: { pathMatch: subCategory.slug } }"
          filter
          class="mr-2 mb-2"
        >
          {{ subCategory.name }}
        </v-chip>
      </div>
    </v-container>
    <v-container>
      <ProductListGrid
        v-bind:parentCategoryId="category.categoryId"
        :key="category.categoryId"
        @productsCount="updateCount"
      />
    </v-container>
  </div>
</template>
<style scoped lang="scss">
.subcategories {
  @media #{map-get($display-breakpoints, 'xs-only')} {
    white-space: nowrap;
    overflow-x: auto;
  }
}
</style>
<script>
import ProductListGrid from "@/components/product/ProductListGrid.vue";
import CategoryTitle from "@/components/category/CategoryTitle.vue";
import Breadcrumb from "@/components/navigation/Breadcrumb.vue";

import { mapProposalComponent } from "~/service/ebsn";

import categoryMixins from "~/mixins/category";
import get from "lodash/get";

export default {
  name: "CategoryMed",
  mixins: [categoryMixins],
  components: {
    ProductListGrid,
    CategoryTitle,
    Breadcrumb,
    ProposalProductListSlider: () =>
      import("@/components/proposal/ProposalProductSlider.vue"),
    ProposalProductGrid: () =>
      import("@/components/proposal/ProposalProductGrid.vue"),
    ProposalSlider: () => import("@/components/proposal/ProposalSlider.vue"),
    ProposalGrid: () => import("@/components/proposal/ProposalGrid.vue")
  },
  data() {
    return { count: -1 };
  },
  computed: {
    ...mapProposalComponent({
      header: "header",
      certificates: "Certificate Banner"
    })
  },
  methods: {
    updateCount(count) {
      this.count = count;
    }
  },
  metaInfo() {
    return {
      title: get(
        this.category,
        "metaData.category_seo.SEO_TITLE",
        this.category ? this.category.name : ""
      ),
      meta: [
        {
          vmid: "description",
          name: "description",
          content: get(
            this.category,
            "metaData.category_seo.SEO_DESCRIPTION",
            this.$t("meta.category.secondAndThirdLevel.description", [
              this.category.name,
              this.breadcrumb[this.breadcrumb.length - 2]?.text
            ])
          )
        },
        {
          vmid: "keywords",
          name: "keywords",
          content: get(this.category, "metaData.category_seo.SEO_KEYWORDS")
        },
        // Open Graph Metas
        {
          property: "og:locale",
          content: "it_IT"
        },
        {
          property: "og:title",
          content: get(
            this.category,
            "metaData.category_seo.SEO_TITLE",
            this.category ? this.category.name : ""
          )
        },
        {
          property: "og:type",
          content: "product.group"
        },
        {
          property: "og:url",
          content: location.href
        },
        {
          property: "og:image",
          content: "https://" + window.location.host + "/logo/social-logo.png"
        },
        {
          property: "og:image:alt",
          content: get(
            this.category,
            "metaData.category_seo.SEO_DESCRIPTION",
            this.$t("meta.category.secondAndThirdLevel.description", [
              this.category.name,
              this.breadcrumb[this.breadcrumb.length - 2]?.text
            ])
          )
        },
        {
          property: "og:site_name",
          content: "DupliClick"
        },
        // Twitter Metas
        {
          name: "twitter:card",
          content: "summary_large_image"
        },
        {
          name: "twitter:title",
          content: get(
            this.category,
            "metaData.category_seo.SEO_TITLE",
            this.category ? this.category.name : ""
          )
        },
        {
          name: "twitter:image",
          content: "https://" + window.location.host + "/logo/social-logo.png"
        },
        {
          name: "twitter:url",
          content: location.href
        }
      ]
    };
  }
};
</script>
